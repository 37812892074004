import React, { ReactNode, useEffect, useRef, useState, createContext, useContext } from 'react';
import { SocketIOProvider } from 'y-socket.io';
import { WALKER_DOMAIN } from '../../constants';
import * as Y from 'yjs';
import { useRequestSocket } from 'components/request/socket';

const SyncFieldContext = createContext<any>({});

export const useSyncField = () => useContext(SyncFieldContext);

const SyncedField = ({ field, children }: { field: any; children: ReactNode }) => {
  const { user } = useRequestSocket();
  const [inputValue, setInputValue] = useState('');
  const [fieldCursors, setFieldCursors] = useState({});
  const docRef = useRef(new Y.Doc());
  const providerRef = useRef(null as any);
  const yTextRef = useRef(null as any);
  const yCursorsRef = useRef(null as any);
  const fieldRef = useRef(null as any);

  const updateInput = () => {
    setInputValue(yTextRef.current.toString());
  };

  yTextRef.current = docRef.current.getText(field.technical.name);
  yCursorsRef.current = docRef.current.getMap('cursors');

  useEffect(() => {
    const doc = docRef.current;
    providerRef.current = new SocketIOProvider(WALKER_DOMAIN, field.technical.name, docRef.current, {});

    yTextRef.current.observe(() => {
      updateInput();
    });

    updateInput();

    return () => {
      providerRef.current.disconnect();
      doc.destroy();
    };
  }, [field.technical.name]);

  const cursors = docRef.current.getMap('cursors');

  useEffect(() => {
    const removeCursorPosition = () => {
      cursors.delete(user.id);
    };
    const updateCursorPosition = () => {
      const position = { index: fieldRef.current.selectionStart, length: 0, user };
      cursors.set(user.id, position);
    };
    const field = fieldRef.current;
    field.addEventListener('click', updateCursorPosition);
    field.addEventListener('keyup', updateCursorPosition);
    field.addEventListener('focus', updateCursorPosition);
    field.addEventListener('blur', removeCursorPosition);

    return () => {
      field?.removeEventListener('click', updateCursorPosition);
      field?.removeEventListener('keyup', updateCursorPosition);
      field?.removeEventListener('focus', updateCursorPosition);
      field?.removeEventListener('blur', removeCursorPosition);
    };
  }, [cursors, user]);

  useEffect(() => {
    cursors.observe(() => {
      const allCursors = cursors.toJSON();
      delete allCursors[user.id];
      setFieldCursors(allCursors);
    });
  }, [cursors, user.id]);

  const handleChange = (value: any) => {
    yTextRef.current.delete(0, yTextRef.current.length);
    yTextRef.current.insert(0, value);
  };

  return <SyncFieldContext.Provider value={{ value: inputValue, handleChange, fieldRef, fieldCursors }}>{children}</SyncFieldContext.Provider>;
};

export default SyncedField;
