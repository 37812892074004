import RequestSocket from 'components/request/socket';
import RequestForm from './form';
import RequestNavigation from './navigation';
import './show.scss';

type RequestShowProps = {
  viewId: string;
  recordId: string;
  authorizationToken: string;
  locale: string;
};

const RequestShow = ({ recordId, viewId, authorizationToken, locale }: RequestShowProps) => {
  return (
    <RequestSocket recordId={recordId} viewId={viewId} authorizationToken={authorizationToken} locale={locale}>
      <div className="request-container">
        <RequestNavigation />
        <RequestForm />
      </div>
    </RequestSocket>
  );
};

export default RequestShow;
