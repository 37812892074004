import classNames from 'classnames';
import ErrorMessage from 'components/form/ui/errorMessage';
import { useFormField } from 'components/form/fieldProvider';
import WarningMessage from 'components/form/ui/warningMessage';
import { fieldWrapper } from 'components/form/decorators/fieldWrapper';
import Cursors from 'components/form/ui/cursors';

const FieldsInput = () => {
  const { input, properties, ref } = useFormField();
  return (
    <>
      <Cursors>
        <input {...input} value={input.value || ''} className={classNames('field-input')} placeholder={properties?.placeholder || ''} ref={ref} />
      </Cursors>
      <ErrorMessage />
      <WarningMessage />
    </>
  );
};

export default fieldWrapper()(FieldsInput);
