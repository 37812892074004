import React, { ReactNode, useEffect } from 'react';
import { useSyncField } from '../syncedField';

interface Cursor {
  user: {
    id: string;
    email: string;
    display_name: string;
    first_name: string;
    last_name: string;
    locale: string;
  };
  index: number;
  length: number;
}

const stringToHash = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
};

const hashToColor = (hash: number): string => {
  const color = (hash & 0xffffff).toString(16).padStart(6, '0');
  return `#${color}`;
};

const generateColorForUser = (userId: string): string => {
  const hash = stringToHash(userId);
  return hashToColor(hash);
};

const Cursors = ({ children }: { children: ReactNode }) => {
  const { fieldCursors, fieldRef } = useSyncField();

  useEffect(() => {
    const userIDs = Object.keys(fieldCursors);

    const removeUselessCursors = (userIDs: string[]) => {
      const elements = document.querySelectorAll('[data-user-id]');
      elements.forEach((element) => {
        const userId = element.getAttribute('data-user-id');
        if (!userId || !userIDs.includes(userId)) {
          element.remove();
        }
      });
    };

    const getInputTextWidth = (atIndex: number): number => {
      const input = fieldRef.current;
      const tempElement = document.createElement('span');
      tempElement.style.fontSize = getComputedStyle(input).fontSize;
      tempElement.style.fontFamily = getComputedStyle(input).fontFamily;
      tempElement.style.fontWeight = getComputedStyle(input).fontWeight;
      tempElement.style.letterSpacing = getComputedStyle(input).letterSpacing;
      tempElement.style.whiteSpace = 'pre';
      tempElement.style.visibility = 'hidden';
      tempElement.textContent = input.value.slice(0, atIndex);
      document.body.appendChild(tempElement);
      const paddingLeft = parseInt(getComputedStyle(input).paddingLeft);
      const borderLeft = parseInt(getComputedStyle(input).borderLeft);
      const width = borderLeft + paddingLeft + tempElement.offsetWidth;
      document.body.removeChild(tempElement);
      return width;
    };

    const toggleUserCursor = (cursor: Cursor) => {
      let cursorElement = document.getElementById(`cursor-${cursor.user.id}`);
      if (!cursorElement) {
        const color = generateColorForUser(cursor.user.email);
        cursorElement = document.createElement('span');
        cursorElement.classList.add('cursor');
        cursorElement.id = `cursor-${cursor.user.id}`;
        cursorElement.setAttribute('data-user-id', cursor.user.id);
        cursorElement.setAttribute('data-user-display-name', cursor.user.display_name);
        cursorElement.setAttribute('data-cursor-color', color);
        cursorElement.style.position = 'absolute';
        cursorElement.style.color = color;
        fieldRef.current.parentElement.appendChild(cursorElement);
      }

      const widthOfTextForCursorPosition = getInputTextWidth(cursor.index);
      const fieldPaddingTop = parseInt(getComputedStyle(fieldRef.current).paddingTop);
      const fieldBorderTop = parseInt(getComputedStyle(fieldRef.current).borderTop);
      cursorElement.style.left = `${widthOfTextForCursorPosition}px`;
      cursorElement.style.top = fieldPaddingTop + fieldBorderTop + 'px';
    };

    removeUselessCursors(userIDs);

    userIDs.map((userId) => toggleUserCursor(fieldCursors[userId]));
  }, [fieldCursors, fieldRef]);

  return <div className="cursor-container">{children}</div>;
};

export default Cursors;
