import React from 'react';
import classNames from 'classnames';

type LabelProps = {
  className?: string;
  label: string;
  htmlFor: string;
};

const Label = ({ className, label, htmlFor }: LabelProps) => {
  return (
    <label htmlFor={htmlFor} className={classNames('field-label', className)}>
      {label}
    </label>
  );
};

export default Label;
