import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Label from 'components/form/ui/label';
import { useFormField } from 'components/form/fieldProvider';

type WithLabel = {
  label?: string;
};

const withLabel = (Component: React.ElementType) => {
  const WithLabel = (props: WithLabel) => {
    const {
      input: { id },
      field: {
        properties: { label },
      },
    } = useFormField();
    return (
      <>
        {!isEmpty(label) && <Label label={label} htmlFor={id} />}
        <Component {...props} />
      </>
    );
  };
  WithLabel.propTypes = {
    label: PropTypes.string,
  };
  return WithLabel;
};

export default withLabel;
