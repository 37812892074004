import React from 'react';
import classNames from 'classnames';
import { useFormField } from 'components/form/fieldProvider';

type WithContainerProps = {
  container?: boolean;
};

const withContainer = (Component: React.ElementType) => {
  const WithContainer = (props: WithContainerProps) => {
    const {
      field: {
        properties: { container, inline },
      },
      input: { disabled },
    } = useFormField();
    if (container === false && !inline) {
      return <Component {...props} />;
    }
    return (
      <div
        className={classNames('field-container', {
          inline,
          disabled,
        })}
      >
        <Component {...props} />
      </div>
    );
  };
  return WithContainer;
};

export default withContainer;
