import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormField } from 'components/form/fieldProvider';

const ErrorMessage = () => {
  const {
    field: { state },
    input: { name },
  } = useFormField();
  if (state.touched && !state?.valid && !isEmpty(state?.errors)) {
    return (
      <span className="field-error" data-error-for={name}>
        {state.errors.join(', ')}
      </span>
    );
  }
  return null;
};

export default ErrorMessage;
