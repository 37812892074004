import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormField } from 'components/form/fieldProvider';

const WarningMessage = () => {
  const {
    meta,
    input: { name },
  } = useFormField();
  if (!isEmpty(meta?.warning)) {
    return (
      <span className="field-warning" data-warning-for={name}>
        {meta?.warning}
      </span>
    );
  }
  return null;
};

export default WarningMessage;
