import React, { ReactNode, useEffect, useState, createContext, useContext, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { WALKER_DOMAIN } from '../../constants';
import isEmpty from 'lodash/isEmpty';

type UserType = {
  id: string;
  email: string;
  display_name: string;
  first_name: string;
  last_name: string;
  locale: string;
};

type RequestSocketProps = {
  children: ReactNode;
  viewId: string;
  recordId: string;
  authorizationToken: string;
  locale: string;
};

type RequestSocketContextType = {
  socket: Socket | null;
  request: any;
  fields: any[];
  onChange: Function;
  onNavigate: Function;
  navigation: any[];
  user: UserType;
};

type ViewInitType = {
  viewReference: string;
  fields: any[];
  navigation: any[];
  user: UserType;
};

const RequestSocketContext = createContext<RequestSocketContextType>({
  socket: null,
  request: {},
  fields: [],
  navigation: [],
  onChange: () => {},
  onNavigate: () => {},
  user: {
    id: '',
    email: '',
    display_name: '',
    first_name: '',
    last_name: '',
    locale: '',
  },
});

export const useRequestSocket = () => useContext(RequestSocketContext);

const RequestSocket = ({ children, recordId, viewId, authorizationToken, locale }: RequestSocketProps) => {
  const socketRef = useRef<Socket | null>(null);
  const [fields, setFields] = useState<any>([]);
  const [navigation, setNavigation] = useState<any>([]);
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    const socketIo = io(WALKER_DOMAIN, {
      reconnectionDelayMax: 10000,
    });
    socketRef.current = socketIo;
    socketRef.current.on('connect', () => {
      if (!isEmpty(viewId)) {
        socketRef.current?.emit('view:init', { viewId, authorizationToken, locale }, ({ fields, navigation }: ViewInitType) => {
          setNavigation(navigation);
          setFields(fields);
        });
      }
      if (!isEmpty(recordId)) {
        //console.log('[MIA] --> INIT RECORD', recordId);
        socketRef.current?.emit('record:init', { recordId, authorizationToken, locale, viewId }, ({ fields, navigation, user }: ViewInitType) => {
          setNavigation(navigation);
          setFields(fields);
          setUser(user);
        });
      }
    });
    socketRef.current.on('record:change', ({ fields, navigation }: ViewInitType) => {
      //console.log('[MIA] <-- GOT RECORD CHANGE', fields);
      setNavigation(navigation);
      setFields(fields);
    });

    socketRef.current.on('view:change', ({ fields, navigation }: ViewInitType) => {
      //console.log('[MIA] <-- GOT VIEW CHANGE', fields);
      setNavigation(navigation);
      setFields(fields);
    });

    socketRef.current.on('disconnect', () => {
      //console.log('[MIA] disconnected');
    });

    //console.log('[MIA] connectSocket');
    return () => {
      console.log('[MIA] disconnectSocket');
      socketIo.disconnect();
    };
  }, [authorizationToken, locale, recordId, viewId]);

  if (!socketRef.current) return null;

  const onChange = (path: string, value: any) => {
    //console.log('--> CHANGE', path, value);
    /*if (!isEmpty(recordId)) {
      socketRef.current?.emit('record:change', { path, value, recordId }, () => {});
    }
    if (!isEmpty(viewId)) {
      socketRef.current?.emit('view:change', { path, value }, () => {});
    }*/
    return;
  };

  const onNavigate = (pageID: string) => {
    if (!isEmpty(recordId)) {
      socketRef.current?.emit('record:navigate', { recordId, pageID }, ({ fields, navigation }: any) => {
        //console.log('[MIA] <-- GOT NEW PAGE', fields);
        setFields(fields);
        setNavigation(navigation);
      });
      return;
    }

    socketRef.current?.emit('view:navigate', { viewId, pageID }, ({ fields, navigation }: any) => {
      //console.log('[MIA] <-- GOT NEW PAGE', fields);
      setFields(fields);
      setNavigation(navigation);
    });
  };
  if (!fields || !navigation || !user) return null;

  return (
    <RequestSocketContext.Provider value={{ socket: socketRef.current, request: {}, fields, onChange, navigation, onNavigate, user }}>
      {children}
    </RequestSocketContext.Provider>
  );
};

export default RequestSocket;
