import { useRequestSocket } from './socket';

type NavigationListType = {
  pages: any[];
};

const NavigationList = ({ pages }: NavigationListType) => {
  const { onNavigate } = useRequestSocket();
  if (!pages || !pages.length) return null;

  return (
    <ul>
      {pages.map((page, index) => {
        return (
          <li key={index}>
            <div role="menuitem" onClick={() => onNavigate(page.id)} className={`navigation-item ${page.state.active ? ' active' : ''}`}>
              {page.title}
            </div>
            <NavigationList pages={page.subPages} />
          </li>
        );
      })}
    </ul>
  );
};

const RequestNavigation = () => {
  const { navigation } = useRequestSocket();
  return (
    <div className="request-navigation">
      <NavigationList pages={navigation} />
    </div>
  );
};

export default RequestNavigation;
