import classNames from 'classnames';
import { fieldWrapper } from 'components/form/decorators/fieldWrapper';
import ErrorMessage from 'components/form/ui/errorMessage';
import { useFormField } from 'components/form/fieldProvider';
import WarningMessage from 'components/form/ui/warningMessage';

export const FieldsRadio = () => {
  const { input } = useFormField();

  return (
    <>
      <input {...input} type="radio" className={classNames('field-radio')} />
      <ErrorMessage />
      <WarningMessage />
    </>
  );
};

export default fieldWrapper()(FieldsRadio);
