import React from 'react';
import { useRequestSocket } from './socket';
import Form from 'components/form/form';

const RequestForm = () => {
  const { fields, request, onChange } = useRequestSocket();

  const onFormChange = (fieldName: string, value: any) => {
    onChange(fieldName, value);
  };

  const onBlur = (fieldName: string) => {
    console.log('onBlur', fieldName);
  };

  const onFocus = (fieldName: string) => {
    console.log('onFocus', fieldName);
  };

  if (!fields.length) return null;

  return (
    <div className="request-form">
      <Form fields={fields} values={request} onFormChange={onFormChange} onBlur={onBlur} onFocus={onFocus} />
    </div>
  );
};

export default RequestForm;
