import { createContext, useContext } from 'react';
import FormField from 'components/form/field';

type FormProps = {
  fields: any;
  values: Object;
  onFormChange: Function;
  onFocus: Function;
  onBlur: Function;
  disabled?: boolean;
};

type FormContextProps = {
  values: Object;
  onChange: Function;
  onFocus: Function;
  onBlur: Function;
  formDisabled: boolean;
};

const FormContext = createContext<FormContextProps>({
  values: {},
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  formDisabled: false,
});

export const useForm = () => useContext(FormContext);

const FormComponent = ({ fields, values, onFormChange, onFocus, onBlur, disabled = false }: FormProps) => {
  const onChange = (fieldName: string, value: any) => {
    onFormChange(fieldName, value);
  };

  return (
    <FormContext.Provider
      value={{
        values,
        onChange,
        onFocus,
        onBlur,
        formDisabled: disabled,
      }}
    >
      <form className="form">
        {fields.map((field: any, index: number) => {
          return <FormField field={field} key={index} />;
        })}
      </form>
    </FormContext.Provider>
  );
};

export default FormComponent;
