import classNames from 'classnames';
import { fieldWrapper } from 'components/form/decorators/fieldWrapper';
import ErrorMessage from 'components/form/ui/errorMessage';
import { useFormField } from 'components/form/fieldProvider';
import WarningMessage from 'components/form/ui/warningMessage';

const FieldsCheckbox = () => {
  const { input } = useFormField();
  return (
    <>
      <input {...input} type="checkbox" className={classNames('field-checkbox')} />
      <ErrorMessage />
      <WarningMessage />
    </>
  );
};

export default fieldWrapper()(FieldsCheckbox);
